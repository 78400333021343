import { 
    get, 
    post 
} from './http'

// 登录页面api
export let login = p => post('/aaa', p); //登录
export let getCode = p => get('/bbb', p); //登录

//获取openid
export let getOpenIdApi = p => get('/h5/qrcode/oauth2/'+p.code+'/'+p.payType);

//获取门店信息
export let getStoreInfoApi = p => get('/h5/qrcode/'+p.shopId);

//预支付
export let paySubmitApi = p => post('/h5/qrcode/pay', p);

//查询支付是否成功
export let getPayStatusApi = p => get('/h5/qrcode/detail/'+p.sysOrderNo);

//支付宝授权
export let zfbAuthApi = p => post('/mer/channelIncome/zfb/exchangeZfbAuthToken/'+p.code+'/'+p.state);