<template>
  <div class="pages">
    <div class="header">
      <img v-if="status==1" src="../../assets/images/pay-success.png" alt="" class="suc-logo" />
      <img v-if="status==2" src="../../assets/images/pay-loading.png" alt="" class="pay-loading" />
      <img v-if="status==0" src="../../assets/images/pay-error.png" alt="" class="store-logo" />
      <img v-if="status==3" src="../../assets/images/load-err.png" alt="" class="load-err" />

      <div class="status-text" v-if="status==0">支付失败，请重新支付</div>
      <div class="status-text" v-if="status==1">支付成功</div>
      <div class="status-text" v-if="status==2">正在处理，请耐心等待</div>
      <div class="load-text" v-if="status==3">哎呀，网络繁忙，查询超时！</div>
    </div>
    <!-- <div class="pay-info" v-if="status==1">
      <div class="store-title">
        {{storeInfo.shopName}}
      </div>
      <div class="pay-money">
        <span class="yuan">￥ </span>{{storeInfo.orderAmount}}
      </div>
    </div> -->
    <!-- <a href="javascript:;" class="lookmore" @click="lookMore">点击查看更多好物</a> -->
    <!-- <van-button class="finish-btn" v-if="status==1">完成</van-button> -->
    <van-button class="finish-btn" @click="rePay" v-if="status==0">重新支付</van-button>
    <!-- <van-button class="req-bth" @click="reRequest" v-if="status==3">重新查询支付结果</van-button> -->
  </div>
</template>

<script>
  import { getPayStatusApi } from "@/request/api";
  export default {
    name:'',
    components: {},
    data() {
        return {
          status: 1, //1支付成功 0支付失败 2处理中 3查询超时
          tradeNo: null,   //商户订单号
          sub_mch_id: null,   //特约商户号
          check_code: null,   //md5 校验码

          storeInfo: {           //门店信息
            shopId: null,
            shopName: null,
            shopLogo: null,
            orderAmount: null,
            qrcodeUrl: null,
          },
          // T: '',//定时器的ID
          // isClick: true,      //防止多次提交
        };
    },
    mounted() {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      // s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
      s.src = "https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js";
      document.body.appendChild(s);
      window.onload =()=>{   //初始化产生一个全局变量 
        this.readys()            
      }
    },
    created () {
      // let that = this;
      // //微信点金计划链接携带参数
      // that.sub_mch_id = that.$route['query']['sub_mch_id'];
      // that.tradeNo = that.$route['query']['out_trade_no'];
      // that.check_code = that.$route['query']['check_code'];
      // if(that.tradeNo){
      //   that.getPayStatus(); //查询支付是否成功
      // }
    },
    methods: {
      readys(){
        let that = this;
        that.$nextTick(function () {
          //设置iframe高度
          // const customPageHeight = document.body.scrollHeight; // 单位 px
          // const height = customPageHeight * (640 / Math.round(document.documentElement.getBoundingClientRect().width));
          // let mchData = {action:'onIframeReady',displayStyle:'SHOW_CUSTOM_PAGE',height:height}
          let mchData = {action:'onIframeReady',displayStyle:'SHOW_CUSTOM_PAGE'}
          let postData = JSON.stringify(mchData)
          parent.postMessage(postData,'https://payapp.weixin.qq.com');
        })
      },
      //再次获取支付结果
      reRequest(){
        let that = this;
        that.status = 2;
        that.getPayStatus();
      },
      //轮询查询支付是否成功
      getPayStatus () {
        let that = this;
        const para = {
          'sysOrderNo': that.tradeNo
        }
        getPayStatusApi(para).then(res => {
          if (res.code == 200) {
            that.storeInfo = res.data;
            //支付状态（1=处理中 2=下单成功 3=下单失败 4=支付成功 5=支付失败 6=已退款）
            if (res.data.payStatus == 4) {
              that.status = 1;
            } else if (res.data.payStatus == 5) {
              that.status = 0;
            }
          } else {
            that.status = 0;
          }
          //设置iframe高度
          const customPageHeight = document.body.scrollHeight; // 单位 px
          const height = customPageHeight * (640 / Math.round(document.documentElement.getBoundingClientRect().width));
          let mchData = {action:'onIframeReady',displayStyle:'SHOW_CUSTOM_PAGE',height:height}
          let postData = JSON.stringify(mchData)
          parent.postMessage(postData,'https://payapp.weixin.qq.com')
        });
      },  
      //重新支付
      rePay(){
        let that = this;
        let mchData ={action:'jumpOut', jumpOutUrl:that.storeInfo.qrcodeUrl}
        let postData = JSON. stringify(mchData)
        parent.postMessage(postData,'https://payapp.weixin.qq.com')
      },
    }
  }
</script>
<style lang="scss">
  body{
    background-color: #ffffff;
  }
  .pages{
      height: 100%;
      padding: 10px 0;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .header{
        text-align: center;
        .suc-logo{
          margin-top: 78px;
          width: 104px;
          height: 87px;
          margin-bottom: 41px;
        }
        .store-logo{
          margin-top: 65px;
          width: 106px;
          height: 106px;
          margin-bottom: 35px;
        }
        .load-err{
          margin-top: 66px;
          width: 361px;
          height: 326px;
          margin-bottom: 56px;
        }
        .status-text{
          font-size: 36px;
          font-weight: bold;
          color: #FA3474;
        }
        .load-text{
          font-size: 36px;
          font-weight: bold;
          color: #999999;
        }
      }
      .pay-info{
        text-align: center;
        margin-top: 155px;
        .store-title{
          font-size: 30px;
          font-weight: 400;
          color: #333333;
        }
        .pay-money{
          margin-top: 37px;
          font-size: 72px;
          font-weight: bold;
          color: #333333;
          .yuan{
            font-size: 30px;
            font-weight: 800;
          }
        }
      }
      .lookmore{
        margin-top: 30px;
        color: #4ea1db;
        text-decoration: underline;
      }
      .finish-btn{
        width: 270px;
        height: 68px;
        line-height: 68px;
        /* background: #F3F3F3; */
        border: 1px solid #FA3474;
        border-radius: 6px;
        color: #FA3474;
        text-align: center;
        font-size: 36px;
        position: absolute;
        bottom: 114px;
      }
      .req-bth{
        width: 360px;
        height: 68px;
        line-height: 68px;
        border: 1px solid #FA3474;
        border-radius: 6px;
        color: #FA3474;
        text-align: center;
        font-size: 36px;
        margin-top: 77px;
      }
  }
  
  .pay-loading{
    margin-top: 65px;
    width: 106px;
    height: 106px;
    margin-bottom: 35px;
    animation: rotate 1.5s infinite linear;
    -webkit-animation: rotate 1.5s infinite linear;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-ms-keyframes rotate {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-o-keyframes rotate {
    from {
      -o-transform: rotate(0deg);
    }
    to {
      -o-transform: rotate(360deg);
    }
  }
</style>